import React from 'react'
import { useLocation } from '@reach/router'

import DrawerItem from './DrawerItem'

import { MenuContainer, ItemContainer, Title } from './styles'

const printSubMenu = [
  {
    title: 't-shirts',
    to: '/prints/t-shirts',
  },
  {
    title: 'sacs',
    to: '/prints/bags',
  },
  {
    title: 'papier',
    to: '/prints/paper-print',
  },
]

const gearSubMenu = [
  {
    title: 'cabs',
    to: '/musicgears/cabs',
  },
  {
    title: 'pedalboards',
    to: '/musicgears/pedalboards',
  },
  {
    title: 'hardcases',
    to: '/musicgears/hardcase',
  },
  {
    title: 'guitares',
    to: '/musicgears/guitar',
  },
]

const LaptomMenu = () => {
  const { pathname } = useLocation()

  return (
    <MenuContainer>
      <ItemContainer>
        <Title to="/" $isCurrent={pathname === '/'}>
          accueil
        </Title>
      </ItemContainer>
      <ItemContainer>
        <Title to="/shop" $isCurrent={pathname.startsWith('/shop')}>
          boutique
        </Title>
      </ItemContainer>
      <DrawerItem
        id="print"
        title="prints"
        subItems={printSubMenu}
        route="/prints"
        pathname={pathname}
      />
      <DrawerItem
        id="music-gear"
        title="instruments"
        subItems={gearSubMenu}
        route="/musicgears"
        pathname={pathname}
      />
      <ItemContainer>
        <Title to="/contact" $isCurrent={pathname === '/contact'}>
          contact
        </Title>
      </ItemContainer>
    </MenuContainer>
  )
}

export default LaptomMenu
