import React, { useLayoutEffect } from 'react'
import { string, bool, func } from 'prop-types'
import { useLocation } from '@reach/router'

import { SimpleItem, ItemWithSub } from './MenuItem'
import {
  MobileMenuContainer, IconContainer, MenuLinkContainer, CloseIcon,
} from './styles'

const MobileNav = ({ id, isOpen, onClose }) => {
  const windowIsAvailable = typeof window !== 'undefined' && window.document
  const { pathname } = useLocation()

  // eslint-disable-next-line consistent-return
  useLayoutEffect(() => {
    if (windowIsAvailable) {
      const outerClickHandler = e => {
        const mobileMenu = document.getElementById(id)

        if (mobileMenu && isOpen) {
          const isOuterClick = !mobileMenu.contains(e.target)

          if (isOuterClick) {
            onClose()
          }
        }
      }

      document.addEventListener('click', outerClickHandler)

      return () => {
        document.removeEventListener('click', outerClickHandler)
      }
    }
  }, [isOpen, id, onClose, windowIsAvailable])

  return (
    <MobileMenuContainer id={id} isOpen={isOpen}>
      <IconContainer>
        <CloseIcon onClick={onClose} />
      </IconContainer>
      <MenuLinkContainer>
        <SimpleItem title="Accueil" to="/" isCurrent={pathname === '/'} />
        <SimpleItem
          title="Boutique"
          to="/shop"
          isCurrent={pathname.startsWith('/shop')}
        />
        <ItemWithSub
          title="Prints"
          pathname={pathname}
          subItems={[
            {
              title: 'T-shirts',
              to: '/prints/t-shirts',
            },
            {
              title: 'Sacs',
              to: '/prints/bags',
            },
            {
              title: 'Impression Papier',
              to: '/prints/paper-print',
            },
          ]}
        />
        <ItemWithSub
          title="Instruments"
          pathname={pathname}
          subItems={[
            {
              title: 'Cabs',
              to: '/musicgears/cabs',
            },
            {
              title: 'Pedalboards',
              to: '/musicgears/pedalboards',
            },
            {
              title: 'Hardcases',
              to: '/musicgears/hardcase',
            },
            {
              title: 'Guitares',
              to: '/musicgears/guitar',
            },
          ]}
        />
        <SimpleItem
          title="Contact"
          to="/contact"
          isCurrent={pathname === '/contact'}
        />
      </MenuLinkContainer>
    </MobileMenuContainer>
  )
}

MobileNav.propTypes = {
  id: string.isRequired,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
}

export default MobileNav
